// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blurb-js": () => import("./../src/pages/blurb.js" /* webpackChunkName: "component---src-pages-blurb-js" */),
  "component---src-pages-categories-index-js": () => import("./../src/pages/categories/index.js" /* webpackChunkName: "component---src-pages-categories-index-js" */),
  "component---src-pages-download-js": () => import("./../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-magazine-js": () => import("./../src/pages/magazine.js" /* webpackChunkName: "component---src-pages-magazine-js" */),
  "component---src-pages-market-js": () => import("./../src/pages/market.js" /* webpackChunkName: "component---src-pages-market-js" */),
  "component---src-pages-new-js": () => import("./../src/pages/new.js" /* webpackChunkName: "component---src-pages-new-js" */),
  "component---src-pages-radio-js": () => import("./../src/pages/radio.js" /* webpackChunkName: "component---src-pages-radio-js" */),
  "component---src-pages-shop-js": () => import("./../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-tags-index-js": () => import("./../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-categories-js": () => import("./../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-product-post-js": () => import("./../src/templates/product-post.js" /* webpackChunkName: "component---src-templates-product-post-js" */),
  "component---src-templates-tags-js": () => import("./../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

